
import { Options, Vue } from 'vue-class-component';

import CodeMirror from '@/common/CodeMirror.vue';
import ClipboardCredentials from '@/common/ClipboardCredentials.vue';

import requests from '@/requests';
import { accessibleAPIPlan } from '@/subscription';

@Options({
  components: {
    CodeMirror,
    ClipboardCredentials,
  },
})
export default class APIAbout extends Vue {
  public api: { [key: string]: string | null | boolean } = {
    url: process.env.VUE_APP_API_URL,
    key: null,
    show: false,
  }

  private getAPIKey(): void {
    this.api.show = true;

    requests.api.getAPIKey().then((res) => { this.api.key = res.data.key; });
  }

  created(): void {
    if (accessibleAPIPlan.some((v) => v === this.$store.state?.profile?.subscription?.plan)) {
      this.getAPIKey();
    }
  }
}
